<template>
  <div>
    <a :href="ebayUrl" class="btn btn-light btn-outline-primary" :disabled="!ebayUrl">
      Authorize eBay
    </a>
  </div>
</template>

<script setup>

import { ref } from 'vue'
import { useStore } from 'vuex'
import {useAuth0} from "@auth0/auth0-vue";

const auth0 = useAuth0();
const store = useStore()
const ebayUrl = ref(null)

store.dispatch('eshop/getEbayAuthUrl', auth0)
    .then(url => {
      ebayUrl.value = url
    })
    .catch(e => console.error(e))

// function testCallback() {
//   const page = window.open('http://localhost:3000/ebay/callback', '_blank')
//   page.focus()
//   window.addEventListener('message', callback, false);
// }

// function callback(e) {
//   console.log('e', e)
//   console.log('opener received message!:', e.data);
//   ebayAccessToken.value = JSON.parse(e.data)
//   //window.removeEventListener('message', callback)c
// }

// function openConnection() {
//   // const page = window.open(ebayUrl.value, '_blank')
//   // page.focus()
//   // window.addEventListener('message', callback, false);
// }

</script>
