<template>
  <div :class="{'overflow-auto p-2 small bg-white border': props.overflow}" :style="props.overflow?'height:300px;':''">
    <div v-for="key in Object.keys(props.obj)" :key="key" class="d-flex">
      <div class="me-1">
        <div class="fw-bold me-1">{{ key }}:</div>
        <div v-if="typeof props.obj[key] === 'object'"  class="ps-3 border-start border-black">
          <object-display :obj="props.obj[key]" :overflow="false" />
        </div>
      </div>
      <div v-if="typeof props.obj[key] !== 'object'">{{ props.obj[key] }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue"
const props = defineProps({
  obj: {
    type: Object,
    required: true,
    default: () => {}
  },
  overflow: {
    type: Boolean,
    default: false,
  }})
</script>
