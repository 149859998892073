<template>
  <Teleport to="body">
    <transition mode="out-in">
      <div v-if="modalIn" class="modal-open">
        <div class="modal-backdrop opacity-50"></div>

        <div class="modal show d-block" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel"><slot name="header"></slot></h5>
                <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <slot />
              </div>
              <div class="modal-footer d-none">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import { defineProps, defineEmits, toRef, watch } from "vue"
const props = defineProps({modalIn:{type:Boolean}})
const propRef = toRef(props, 'modalIn')
const emits = defineEmits(["close"]);

watch(propRef, (newValue) => {
  if (newValue) {
    document.body.classList.add("modal-open")
  } else {
    document.body.classList.remove("modal-open")
  }
})

function closeModal() {
  document.body.classList.remove("position-fixed");
  emits('close')
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position:fixed;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
  z-index: 1;
}
</style>
