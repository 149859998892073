<template>
  <div>
    <transition mode="out-in">
      <loading-spinner v-if="fetching" />
      <div v-else-if="validToken">
        <div class="mb-3">
          ebayCode: <pre class="small">{{ code }}</pre>
        </div>
        <div class="mb-3">
          userToken: <pre class="small">{{ ebayUserToken }}</pre>
        </div>
        <div class="mb-3 text-center">
          <a href="/" class="btn btn-primary">OK</a>
        </div>
      </div>
      <div v-else class="mb-3">
        <connection-ebay />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router' // , useRouter
import { useAuth0 } from "@auth0/auth0-vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ConnectionEbay from "@/components/ConnectionEbay.vue";

const store = useStore()
const route = useRoute()
const auth0 = useAuth0()

const ebayUserToken = ref()
const validToken = ref(false)
const fetching = ref(false)
const code = route.query.code || null
const state = route.query.state || null

if (code) {
  getToken()
} else {
  fetchStoredTokens()
}

// const router = useRouter()
if (state) {
  console.log('state', state)
  // router.replace(`/store/${state}/settings`)
} else {
  // router.replace({name: 'Home'})
}

function setToken(token) {
  ebayUserToken.value = token.token
  validToken.value = token.validToken
  fetching.value = false
  if (token.error) {
    console.log('get token error', token.error)
  }
}

function getToken() {
  store.dispatch('eshop/getEbayUserToken', {auth0, code, type: 'code'})
      .then(setToken)
    .catch(e => {
      setToken({validToken: false, token:null, error: e})
    })
}

function fetchStoredTokens() {
  fetching.value = true
  store.dispatch('eshop/fetchStoredEbayTokens', { auth0 })
      .then(setToken)
  .catch(e => {
    console.log('fetchStoredTokens error', e)
    setToken({validToken: false, token:null, error: e})
  })
}
</script>
