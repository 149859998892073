<template>
  <div>
    <transition mode="out-in">
      <loading-spinner v-if="fetching" />
      <div v-else-if="error" class="alert alert-warning">
        {{ error }}
      </div>
      <div v-else>
        <div class="p-2 text-center text-white fw-bold mb-3"
        :class="{'bg-success': order.orderFulfillmentStatus==='FULFILLED', 'bg-warning': order.orderFulfillmentStatus!=='FULFILLED'}">
          SHIPPING: {{ order.orderFulfillmentStatus }}
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <h3>Buyer</h3>
            <div>{{ order.buyer?.username }}</div>
            <address-block :address="order.buyer?.buyerRegistrationAddress" />
          </div>

          <div class="col-4">
            <div v-for="(fulfillment, idx) in order.fulfillmentStartInstructions || []" :key="idx">
              <div v-if="fulfillment.shippingStep && fulfillment.shippingStep.shipTo">
                <h3>Ship To</h3>
                <address-block :address="fulfillment.shippingStep?.shipTo" />
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card shadow">
              <div class="card-body">
                <h2>Order Summary</h2>
                <div class="d-flex" v-for="key in Object.keys(order.pricingSummary)" :key="key">
                  <div class="me-3 fw-bold">{{ key }}</div>
                  <div class="ms-auto">${{ parseFloat(order.pricingSummary[key].value).toFixed(2) }}</div>
                </div>

                <hr>

                <div class="d-flex" v-if="order.totalFeeBasisAmount">
                  <div class="me-3 fw-bold">Fee Basis (w/ Taxes)</div>
                  <div class="ms-auto">${{ parseFloat(order.totalFeeBasisAmount.value).toFixed(2) }}</div>
                </div>

                <div class="d-flex" v-if="order.totalMarketplaceFee">
                  <div class="me-3 fw-bold">Seller Fees</div>
                  <div class="ms-auto text-danger">${{ -parseFloat(order.totalMarketplaceFee.value).toFixed(2) }}</div>
                </div>

                <hr>

                <div class="d-flex">
                  <div class="me-3 fw-bold">Total Due Seller</div>
                  <div class="ms-auto fw-bold text-success">${{ parseFloat(order.paymentSummary?.totalDueSeller?.value).toFixed(2) }}</div>
                </div>

              </div>
            </div>


          </div>
        </div>

        <h2>Line Items</h2>

        <div v-for="line in order.lineItems" :key="line.lineItemId" class="pb-3 mb-3 border-bottom">
          <div class="row">
            <div class="col-10">
              <ebay-item v-if="line.legacyItemId" :item="{'ItemID': line.legacyItemId, paid: line.lineItemCost?.value }" />
              <div v-else>{{ line.title }}</div>
            </div>
            <div class="col-2 small">
              <span class="fw-bold">Quantity X {{ line.quantity }}</span>
              <div class="d-flex">
                <div class="me-3 fw-bold">Price</div>
                <div class="ms-auto">${{ parseFloat(line.lineItemCost?.value).toFixed(2)}} </div>
              </div>
              <div class="d-flex">
                <div class="me-3 fw-bold">Shipping</div>
                <div class="ms-auto">${{ parseFloat(line.deliveryCost?.shippingCost?.value || 0).toFixed(2) }}</div>
              </div>
              <div class="d-flex" v-if="line.deliveryCost?.discountAmount">
                <div class="ms-2 me-3 fw-bold">Discount</div>
                <div class="ms-auto">${{ -parseFloat(line.deliveryCost?.discountAmount?.value || 0).toFixed(2) }}</div>
              </div>
              <div class="d-flex">
                <div class="me-3 fw-bold">Due Seller</div>
                <div class="ms-auto fw-bold">${{ parseFloat(line.total?.value || 0).toFixed(2)}}</div>
              </div>
              <hr>
              <div class="fw-bold">Collected by eBay</div>
              <div class="d-flex" v-if="line.taxes && line.taxes.length">
                <div class="me-3 fw-bold">Taxes</div>
                <div class="ms-auto">${{ line.taxes.reduce((v, t) => v + parseFloat(t.amount?.value || 0), 0).toFixed(2)}}</div>
              </div>
              <div class="d-flex" v-if="line.ebayCollectAndRemitTaxes">
                <div class="me-3 fw-bold">Taxes</div>
                <div class="ms-auto">${{ line.ebayCollectAndRemitTaxes.reduce((v, t) => v + parseFloat(t.amount?.value || 0), 0).toFixed(2)}}</div>
              </div>

            </div>
          </div>
        </div>

        <div class="small d-none">
          <object-display :obj="order" :overflow="true" class="small" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
  import {ref, defineProps} from "vue";
  import {useStore} from "vuex";
  import { useAuth0 } from "@auth0/auth0-vue";

  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import EbayItem from "@/components/EbayItem.vue";
  import ObjectDisplay from "@/components/ObjectDisplay.vue";
  import AddressBlock from "@/components/AddressBlock.vue";

  const props = defineProps(['orderId'])
  const store = useStore()
  const auth0 = useAuth0()
  const order = ref(null)
  const error = ref(null)
  const fetching = ref(false)

  getOrder()

  function getOrder() {
    if (!props.orderId) {
      error.value = 'No Order ID'
      return
    }
    fetching.value = true
    store.dispatch('eshop/getEbayOrder', {auth0, orderId: props.orderId})
        .then(res => {
          order.value = res
          fetching.value = false
        })
        .catch(err => {
          error.value = err
          fetching.value = false
        })
  }
</script>

<style lang="scss" scoped>
.right-column {width : 200px;}
</style>
