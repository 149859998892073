<template>
  <div>

    <div class="d-flex mb-3">
      <span class="mx-2 btn" :class="{ 'btn-primary':int===interval, 'btn-light':int!==interval }"
            v-for="int in intervals" :key="int" @click="setInterval(int)" type="button">{{ int.label }}</span>
      <span class="mx-2">
        <input type="date" class="form-control" v-model="startDate" @change="loadSales">
      </span>
      <span class="mx-2">
        <input
            type="range"
            v-model="range"
            :min="(new Date('2024-07-01')).getTime()"
            :max="(new Date()).getTime()" class="form-range"
            :step="interval.step"
            @change="setRange"
        >
      </span>
      <span class="mx-2">{{ (new Date(parseInt(range))).toLocaleDateString() }}</span>
    </div>

    <apexchart
        :options="salesOptions"
        :series="salesSeries"
        @click="onMarkerClick"
    ></apexchart>




  <table class="table table-responsive table-sm d-none">
    <tbody v-for="(trans, idx) in salesReport" :key="idx">
      <tr v-if="idx===0">
        <th v-for="key in Object.keys(trans)" :key="key">{{ key }}</th>
      </tr>
      <tr>
        <td v-for="key in Object.keys(trans)" :key="key">{{ trans[key] }}</td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script setup>
import {computed, nextTick, ref} from 'vue'
  import { useStore } from 'vuex'
  import { useAuth0 } from "@auth0/auth0-vue";

  const store = useStore()
  const auth0 = useAuth0()
  const salesReport = ref([])
  const range = ref((new Date('2024-07-01')).getTime())

function getEarlierDate(daysEarlier) {
  const date = new Date();
  const minDate = new Date('2024-07-01');
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - daysEarlier);
  return newDate > minDate ? newDate.toISOString().substring(0,10) : minDate.toISOString().substring(0,10);
}

  const intervals = ref([
    {
      label: 'Past Year (by Month)',
      dateFormat: '%Y-%m',
      startDate: getEarlierDate(365),
      step: 86400 * 1000 * 30
    },
    {
      label: 'Past Year (by Week)',
      dateFormat: '%Y-%v',
      startDate: getEarlierDate(365),
      step: 86400 * 1000 * 7
    },
    {
      label:'Past 31 Days (by Day)',
      dateFormat:'%Y-%m-%d',
      startDate: getEarlierDate(31),
      step: 86400 * 1000
    },
    {
      label:'Last 7 Days',
      dateFormat:'%Y-%m-%d',
      startDate: getEarlierDate(7),
      step: 86400 * 1000
    },
  ])

  const interval = ref(intervals.value[2])
  const startDate = ref(intervals.value[2].startDate)

  const salesSeries = computed(() => ([
    {
      name: 'expenses',
      type: 'bar',
      data: salesReport.value.map(c => (parseFloat(c.shipping || 0)
          + parseFloat(c.refunds || 0)
          + parseFloat(c.fees || 0)
          + parseFloat(c.marketing || 0)
          + parseFloat(c.disputes || 0)).toFixed(2)),
    },
    {
      name: 'income',
      type: 'bar',
      data: salesReport.value.map(c => (parseFloat(c.gross_sales || 0)
          + parseFloat(c.credits || 0)).toFixed(2))
    },
    {
      name: 'net',
      type: 'line',
      data: salesReport.value.map(c => parseFloat(c.net_sales || 0).toFixed(2)),
    }
    ]))
  const salesOptions = computed(() => ({
    chart: {
      height: "auto",
      type: "line",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2]
    },
    title: {
      text: 'Sales & Expense Report'
    },
    labels: salesReport.value.map(c => c.sale_date),
    colors: ["#CC0000","#006600","#0000CC"],
    stroke: {width: [0, 0, 6]},
  }))

  loadSales()

  async function setRange() {
    await nextTick()
    const newInterval = { ...interval.value, startDate: new Date(parseInt(range.value)).toISOString().substring(0,10) }
    console.log('new date range', newInterval)
    setInterval(newInterval)
  }

  function setInterval(newInterval) {
      interval.value = newInterval
      startDate.value = newInterval.startDate
      range.value = (new Date(newInterval.startDate)).getTime()
      loadSales()
  }



  function onMarkerClick(e, chartEvt, opts) {
    console.log(chartEvt, opts, opts.seriesIndex, salesSeries.value[opts.seriesIndex])
  }

  async function loadSales () {
    await nextTick()
    store.dispatch('eshop/getSales', {auth0: auth0, fromDate: startDate.value, interval: interval.value.dateFormat})
        .then(data => {
          salesReport.value = data
        }).catch(e => {
          console.log('getSales error', e)
        })
  }
</script>

<style lang="scss" scoped>

</style>
