<template>
  <div class="row" v-if="props.item">
    <div class="col-2">
      <img
          v-if="props.item.PictureDetails && props.item.PictureDetails.PictureURL"
          :src="props.item.PictureDetails.PictureURL[0]"
          class="img-fluid rounded border border-secondary shadow">
    </div>
    <div class="col-10">
      <div v-if="props.item.ListingDetails" class="lead">
        <a :href="props.item.ListingDetails.ViewItemURL" target="_blank">
        {{ props.item.Title }}
        </a>
      </div>
      <div v-if="props.item.SellingStatus" class="fw-bold">${{ parseFloat(props.item.SellingStatus.CurrentPrice.value).toFixed(2) }} <small class="text-muted">{{ props.item.Currency }}</small></div>
      <div v-if="props.item.ConditionDescription">CONDITION: {{ props.item.ConditionDescription }}</div>
      <div class="d-flex">
        <strong class="me-3">{{ props.item.ItemID }}</strong>
        <span class="ms-auto small text-muted">
          <strong>Listed:</strong> {{ (new Date(props.item.ListingDetails.StartTime)).toLocaleDateString() }}
          <strong>Ends:</strong> {{ (new Date(props.item.ListingDetails.EndTime)).toLocaleDateString() }}
        </span>
      </div>
      <small class="d-none"><object-display :obj="props.item" :overflow="true" /></small>
    </div>

  </div>
</template>

<script setup>
 import { defineProps } from "vue"
 import ObjectDisplay from "@/components/ObjectDisplay.vue";
 const props = defineProps({
   item: {
     type: Object,
     required: true,
     default: () => {}
   }
 })
</script>

<style lang="scss" scoped>

</style>
