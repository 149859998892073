<template>
  <transition mode="out-in">
    <loading-spinner v-if="fetching" />
    <div v-else-if="itemDetails">
      <div class="row">
        <div v-if="itemDetails.PictureDetails && itemDetails.PictureDetails.PictureURL" class="col-3"><img :src="itemDetails.PictureDetails.PictureURL[0]" class="img-fluid border rounded shadow" /></div>
        <div class="col-9">
          <div class="lead">
            <a :href="itemDetails.ListingDetails?.ViewItemURL" target="_blank">
              {{ itemDetails.Title }}
            </a>
          </div>
          <div class="lead">
            <strong>${{ parseFloat(itemDetails.SellingStatus.CurrentPrice.value).toFixed(2) }}</strong>
            <small class="ms-2">{{ itemDetails.SellingStatus?.HighBidder?.UserID }}
              paid <strong>${{ parseFloat(item.paid || 0).toFixed(2) }}</strong>
            </small>
          </div>
          <div class="small row bg-info-subtle m-3 p-1">
            <div class="col-4 text-center">ID: <strong>{{ itemDetails.ItemID }}</strong></div>
            <div class="col-4 text-center">SKU: <strong>{{ itemDetails.SKU }}</strong></div>
            <div class="col-4 text-center">Weight:
              <strong>
                {{ itemDetails.ShippingPackageDetails?.WeightMajor?.value }}
                {{ itemDetails.ShippingPackageDetails?.WeightMajor?.unit }}
                {{ itemDetails.ShippingPackageDetails?.WeightMinor?.value }}
                {{ itemDetails.ShippingPackageDetails?.WeightMinor?.unit }}
              </strong>
            </div>
          </div>



          <div class="small d-none">
            <object-display :obj="itemDetails" :overflow="true" class="small" />
          </div>

        </div>
      </div>
    </div>
    <div v-else>
      {{ props.item }}
    </div>
  </transition>
</template>

<script setup>
  import { defineProps, ref, nextTick } from "vue"
  import { useStore } from "vuex"
  import { useAuth0 } from "@auth0/auth0-vue"
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import ObjectDisplay from "@/components/ObjectDisplay.vue";

  const props = defineProps( ['item'] )
  const store = useStore()
  const auth0 = useAuth0()
  const fetching = ref(true)
  const itemDetails = ref(null)

  getItem()

  async function getItem() {
    await nextTick()
    if (!props.item) return
    fetching.value = true
    store.dispatch('eshop/getEbayItem', {auth0, itemId: props.item.ItemID})
        .then(res => {
          itemDetails.value = res
          fetching.value = false
        })
        .catch(err => {
          itemDetails.value = err
          fetching.value = false
        })
  }
</script>

<style lang="scss" scoped>
.d-left {width: 100%; max-width: 150px;}
</style>
