<template>
  <div>
    <button type="button" class="btn btn-primary" @click="getSellerItems()">Fetch eBay Data</button>
    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else-if="items">
        <div>{{ fetchDetails.PaginationResult.TotalNumberOfEntries }} Active Items</div>
        <div>{{ fetchDetails.PageNumber }} of {{ fetchDetails.PaginationResult.TotalNumberOfPages }} pages</div>

        <div v-for="item in filtered"
            :key="item.ItemID"
            :class="{'bg-success-subtle': selectedItem === item}"
        >
          <ebay-item-list-detail :item="item" />
          <div class="border-bottom d-flex" @click="selectItem(item)" role="button">
            {{  item.ItemID  }}
          </div>
          <ebay-item v-if="item === selectedItem" :item="item" class="p-3"/>
        </div>

        <div v-if="fetchDetails.HasMoreItems" class="text-center">
          <div class="small mb-3">{{ fetchDetails.PageNumber }} of {{ fetchDetails.PaginationResult.TotalNumberOfPages }} pages</div>
          <button type="button" class="btn btn-primary" @click="loadMore">Load More</button>
        </div>

      </div>
    </transition>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useAuth0} from "@auth0/auth0-vue";
import EbayItem from "@/components/EbayItem.vue";
import EbayItemListDetail from "@/components/EbayItemListDetail.vue";


const loading = ref(false)
const items = ref(null)
const fetchDetails = ref(null)
const selectedItem = ref(null)
const page = ref(1)
const store = useStore()
const auth0 = useAuth0()
const filtered = computed(() => {
  if (!items.value) return []
  return items.value.slice().sort((a, b) => new Date(b.ListingDetails.StartTime) - new Date(a.ListingDetails.StartTime))
})

function selectItem(item) {
  if (item === selectedItem.value) {
    selectedItem.value = null
    return
  }
  selectedItem.value = item
}

function loadMore() {
  page.value ++
  getSellerItems()
}

getSellerItems()
function getSellerItems() {
  loading.value = true
  store.dispatch('eshop/getSellerItems', {auth0, granularity: 'Coarse', limit: 10, page: page.value })
      .then(list => {
        items.value = list.ItemArray.Item || []
        fetchDetails.value = { ...list, ItemArray: [] }
        loading.value = false
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
      .catch(error => {
        items.value = error.response ? error.response.data : error.message || error
        loading.value = false
        console.log('getSellerItems error', error)
      })
}
</script>

<style lang="scss" scoped>

</style>
