<template>
  <div>


    <div class="mb-3">
      <div class="input-group input-group-sm">
        <span class="input-group-text">{{ transactionType }} Days Ago</span>
        <input type="number" v-model="daysAgo" class="form-control">

        <span class="input-group-text">Days Spanned</span>
        <input type="number" v-model="span" class="form-control">

        <button type="button" class="btn btn-primary" @click="getEbayTransactions(false)">Fetch eBay Data</button>
      </div>
    </div>

    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else>
        {{ dates }}
      </div>
    </transition>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { ref, defineProps, defineEmits } from 'vue'
import { useAuth0 } from "@auth0/auth0-vue"
import { useStore } from "vuex"

const auth0 = useAuth0()
const store = useStore()

const props = defineProps({
  transactionType: {
    type: String,
    required: false,
    default: 'ALL'
  }
})

const emits = defineEmits(['onUpdate'])

const daysAgo = ref(3)
const span = ref(3)
const dates = ref('')
const inputs = ref([])
const loading = ref(false)

function getEbayTransactions() {
  inputs.value = []
  loading.value = true
  const fdate = new Date()
  const tdate = new Date()
  fdate.setDate(fdate.getDate() - daysAgo.value)
  tdate.setDate(tdate.getDate() - daysAgo.value + span.value)
  dates.value = `${ fdate.toISOString() }..${ tdate.toISOString() }`

  store.dispatch('eshop/getEbayData', {
    auth0,
    type: props.transactionType || 'ALL',
    dates: dates.value //'2024-09-07T05:00:01.000Z..2024-09-09T05:00:01.000Z'
  })
      .then(() => {
        emits('onUpdate')
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        console.log('getEbayData error', error)
      })
}

</script>
