<template>
  <div>

<!--    <recent-transactions transaction-type="payouts" />-->

    <recent-transactions @on-update="loadTransactions" />

    <div class="mb-3">
      <input type="date" v-model="date" class="form-control" @change="loadTransactions">
    </div>
    <div class="d-flex mb-3  align-content-center flex-wrap">
      <div
          v-for="type in ['SHIPPING_LABEL','REFUND','NON_SALE_CHARGE','SALE','DISPUTE','CREDIT']"
          :key="type" type="button"
          class="badge badge-pill m-2 flex-fill"
          :class="{'bg-success-subtle text-dark border border-success': excludeTypes.indexOf(type) === -1, 'bg-light border border-dark text-dark': excludeTypes.indexOf(type) !== -1}"
          @click="e=>toggleType(e,type)"
      >
        {{ type}}
      </div>
    </div>

      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th>Date</th>
            <th>Memo</th>
            <th>Type</th>
            <th class="text-end">Amount</th>
            <th class="text-end">Actions</th>
          </tr>
        </thead>
        <transition mode="out-in">

          <tbody v-if="loading">
            <tr>
              <td colspan="5" class="text-center p-5">
                <loading-spinner v-if="loading" />
              </td>
            </tr>
          </tbody>


          <tbody v-else>
            <transaction-row v-for="input in transactions" :key="input.externalId" :input="input" />
          </tbody>
        </transition>
      </table>

  </div>
</template>

<script setup>
import {computed, ref, nextTick} from "vue"
import {useStore} from "vuex"
import {useAuth0} from "@auth0/auth0-vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TransactionRow from "@/components/TransactionRow.vue";
import RecentTransactions from "@/components/RecentTransactions.vue";

const store = useStore()
const auth0 = useAuth0()
const loading = ref(false)
const d = new Date()
d.setDate(d.getDate() - 3)
const date = ref(`${ d.toISOString().substring(0,10) }`)
const excludeTypes = ref([])

function toggleType(e, type) {
  if (excludeTypes.value.indexOf(type) !== -1) {
    excludeTypes.value = excludeTypes.value.filter(t => t !== type)
  } else {
    excludeTypes.value.push(type)
  }
}

let loadedTransactions = ref([])
const transactions = computed(() => {
  if (!loadedTransactions.value) return []
  return loadedTransactions.value.slice().filter(t => excludeTypes.value.indexOf(JSON.parse(t.notes).transactionType) === -1)
})

loadTransactions()

async function loadTransactions() {
  loading.value= true
  await nextTick()
  store.dispatch('eshop/getTransactions', { auth0, date:date.value })
      .then(results => {
        loading.value= false
        loadedTransactions.value = results
      })
      .catch(e => {
        loading.value= false
        alert(e.message || e)
      })
}

</script>
