<template>
  <div>
    <h1>Settings</h1>

    <h2>Website</h2>
    <div class="mb-5">
      <config-edit data-key="WEBSITE_URL" />
      <config-edit data-key="INVENTORY_FEED" />
    </div>

    <h2>eBay</h2>
    <div class="mb-5">
      <config-edit data-key="EBAY_USER_ACCESS_TOKEN" />
      <div class="small text-muted">{{ ebayCode }}</div>
    </div>

    <h2>Wave Accounting</h2>
    <div class="mb-5">
      <config-edit data-key="WAVE_STORE_ID" class="mb-3" />

      <config-edit data-key="WAVE_ACCESS_TOKEN" class="mb-3" />

      <div v-if="store.state.eshop.store.config.WAVE_ACCESS_TOKEN || false">
        <div v-for="key in configs" :key="key" class="mb-3">
          <config-edit :data-key="key" />
        </div>
      </div>
    </div>

    <h2>Delete eShop</h2>
    <router-link :to="{name: 'DeleteStore', params: {storeKey}}" class="btn btn-danger">
      Remove eShop...
    </router-link>



  </div>
</template>

<script setup>

import ConfigEdit from "@/components/ConfigEdit.vue";
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

const store = useStore()
const auth0 = useAuth0()
const configs = computed(() => store.state.eshop.globalConfigs)
const ebayCode = computed(() => store.state.eshop.ebayCode)
// const storeKey = computed(() => store.state.eshop.store && store.state.eshop.store.id)


store.dispatch('eshop/getWaveAccounts', 0)
    .catch(e => console.log(e))

store.dispatch('eshop/getAccounts', {auth0})
    .catch(e => console.log(e))

</script>
